import { example } from "../../type.d";

export default [
	{
		name:"基本用法（base）",
		template:{
			component:"su-form",
			slot:[
				{
					name:"default",
					data:[
						{
							component:"su-form-item",
							slot:[
								{
									name:"default",
									data:[
										{
											component:"su-input",
											props:{
												"v-model":"form.f1"
											},
										},
									],
								},
							],
							props:{
								label:"f1",
								labelAlign:"top",
							},
						},
						{
							component:"su-form-item",
							slot:[
								{
									name:"default",
									data:[
										{
											component:"su-input",
											props:{
												"v-model":"form.f2"
											},
										},
									],
								},
							],
							props:{
								label:"f2",
								col:12,
							},
						},
						{
							component:"su-form-item",
							slot:[
								{
									name:"default",
									data:[
										{
											component:"su-select",
											props:{
												"v-model":"form.f3",
												":options":"selOption",
											},
										},
									],
								},
							],
							props:{
								label:"f3",
								col:12,
							},
						},
						{
							component:"su-form-item",
							slot:[
								{
									name:"default",
									data:[
										{
											component:"su-input",
											props:{
												"v-model":"form.f4"
											},
										},
									],
								},
							],
							props:{
								label:"f4",
							},
						},
						{
							component:"su-form-item",
							slot:[
								{
									name:"default",
									data:[
										{
											component:"su-input",
											props:{
												"v-model":"form.f5"
											},
										},
									],
								},
							],
							props:{
								label:"f5",
							},
						},
					],
				},
			],
			props:{
				':labelWidth': 50,
			},
		},
		data:{
			form:{
				f1:"11",
				f2:"22",
			},
			selOption:[
				{label:"label1",value:"1"},
				{label:"label2",value:"2"},
			]
		},
	},
] as example[];