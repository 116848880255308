export default [
	{
		name:"基本用法（base）",
		template:{
			isText:false,
			component:"su-button",
			slot:[
				{
					name:"default",
					data:[
						{
							isText:true,
							text:"按钮"
						}
					]
				}
			]
		},
	},
	{
		name:"小尺寸",
		template:{
			isText:false,
			component:"su-button",
			props:{
				"size":"mini"
			},
			slot:[
				{
					name:"default",
					data:[
						{
							isText:true,
							text:"按钮"
						}
					]
				}
			]
		},
	},
]