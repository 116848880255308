import { example } from "../type.d";

export default [
	{
		name:"横向",
		template:{
			isText:false,
			component:"su-h-layout",
			slot:[
				{
					name:"default",
					data:[
						{
							isText:false,
							component:"div",
							props:{
								style:"height:100%;width:100px;background-color:#08F"
							}
						},
						{
							isText:false,
							component:"div",
							props:{
								style:"height:100%;width:100px;background-color:#FF0"
							}
						},
						{
							isText:false,
							component:"su-main",
							props:{
								style:"height:100%;background-color:#F80"
							}
						},
						{
							isText:false,
							component:"div",
							props:{
								style:"height:100%;width:100px;background-color:#F0F"
							}
						}
					]
				}
			],
			props:{
				style:"width:100%;height:100px;"
			}
		}
	},
	{
		name:"纵向",
		template:{
			isText:false,
			component:"su-v-layout",
			slot:[
				{
					name:"default",
					data:[
						{
							isText:false,
							component:"div",
							props:{
								style:"width:100%;height:20px;background-color:#08F"
							}
						},
						{
							isText:false,
							component:"div",
							props:{
								style:"width:100%;height:20px;background-color:#FF0"
							}
						},
						{
							isText:false,
							component:"su-main",
							props:{
								style:"width:100%;background-color:#F80"
							}
						},
						{
							isText:false,
							component:"div",
							props:{
								style:"width:100%;height:20px;background-color:#F0F"
							}
						}
					]
				}
			],
			props:{
				style:"width:100px;height:50vh;"
			}
		}
	},
] as example[];