
import { Options, Vue } from 'vue-class-component';
import showData from "./datas";
import RenderComponent from "./render-component"
import ShowCode from "./show-code.vue"

import { example } from "./type.d";


@Options({
	components:{
		RenderComponent,
		ShowCode
	},
	props: {
		name:{
			type:String,
			default:""
		},
	}
})
export default class Examples extends Vue {
	name!:string;
	get list():example[]{
		if(this.name in showData){
			return showData[this.name];
		}
		return [];
	}
	mounted(){
		console.log((this as any)._)
	}
}
